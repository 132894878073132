import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const StyledWebstoreBasics = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    background: ${(props) => props.theme.colors.background.container};
    padding: 60px 0 0;
    margin: 0 auto;

    .basics-header {
        display: flex;
        justify-content: flex-start;
        position: relative;
        align-items: center;
    }

    h1 {
        line-height: 40px;
        margin-block: 0;
        text-transform: unset;
        display: inline-flex;
        align-items: center;
    }
    .verifiedBadge {
        margin-left: 5px;
    }

    .basics-controls {
        margin-left: auto;
    }

    .CYBLink {
        color: ${(props) => props.theme.colors.primary.base};
        cursor: pointer;
    }

    .subtitle {
        display: flex;
        flex-flow: row wrap;
        column-gap: 16px;

        .city-name {
            text-transform: capitalize;
        }

        .state-abbreviation {
            text-transform: uppercase;
        }

        .p1,
        .p2 {
            margin-bottom: 0;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        margin: unset;
        padding: 20px 16px 5px;

        .subtitle > * {
            margin-top: 8px;
            margin-bottom: unset;
        }

        .separator {
            margin-top: 5px;
        }

        .basics-header {
            flex-direction: column-reverse;
        }

        .basics-controls {
            align-self: start;
            padding: 0 0 16px 0;
        }
    }

    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        width: 80%;
        min-width: 740px;
    }

    @media (min-width: 1400px) {
        width: 60%;
        min-width: 1130px;
    }
`;

export { StyledWebstoreBasics };
