import { Outlet, useLocation } from 'react-router-dom';
import { GlobalNavigation } from 'shared/components/Navigation/GlobalNavigation/GlobalNavigation';
import { MainLayoutStyled } from './MainLayout.styled';
// import { MobileFooterNavigation } from '../Navigation/MobileFooterNavigation/MobileFooterNavigation';
import { GlobalFooter } from '../GlobalFooter/GlobalFooter';
import { useEffect, useState } from 'react';
import { nonFooterRoutes } from '../../constants/navigation';
import { newPageTrigger } from 'LivePerson';

function MainLayout() {
    const location = useLocation();

    const isNonFooterRoute = () => nonFooterRoutes.includes(location.pathname);
    const [excludeFooter, setExcludeFooter] = useState(isNonFooterRoute());

    useEffect(() => {
        newPageTrigger(window.location.href);
    }, [window.location.href]);

    useEffect(() => {
        setExcludeFooter(isNonFooterRoute());
    }, [location]);

    return (
        <MainLayoutStyled>
            <GlobalNavigation />
            <Outlet />
            {/* <MobileFooterNavigation /> */}
            {!excludeFooter && <GlobalFooter />}
        </MainLayoutStyled>
    );
}

export { MainLayout };
