import React from 'react';
import Store, { StoreProvider } from 'stores/Store';
import { TealiumProvider } from './Tealium';
import { PearlRoutes } from './routes/PearlRoutes';
import { RealtimeProvider } from 'contexts/RealtimeContext';
import { PearlThemeProvider } from 'dbi-pearl-ui-kit';
import { PearlModalProvider, PearlToastProvider } from './framework';
import { GlobalMessageListeners } from './framework/components/GlobalMessageListeners';
import { PearlSearchProvider } from './event-user/contexts/PearlSearchContext';
import { PageTitle } from './vendor/components/PageTitle';
import { createTheme } from '@mui/material/styles';
import { ApiLoader } from 'shared/components/ApiLoader/ApiLoader';
import { StatusBar } from 'vendor/components/StatusBar/StatusBar';
import { ImpersonationBanner } from './shared/components/Impersonation/ImpersonationBanner';
import { ApiLoadingProvider } from 'framework/lib/apis/ApiLoaderContext';

export default function App() {
    const store = new Store();
    const muiTheme = createTheme();

    setTimeout(() => {
        window.prerenderReady = true;
    }, 5000);

    return (
        <RealtimeProvider store={store}>
            <PearlThemeProvider theme={muiTheme}>
                <ApiLoadingProvider>
                    <ApiLoader />
                    <PearlToastProvider>
                        <StoreProvider store={store}>
                            <ImpersonationBanner>
                                <PearlModalProvider>
                                    <GlobalMessageListeners />
                                    <PearlSearchProvider>
                                        <PageTitle />
                                        <StatusBar />
                                        <TealiumProvider>
                                            <PearlRoutes store={store} />
                                        </TealiumProvider>
                                    </PearlSearchProvider>
                                </PearlModalProvider>
                            </ImpersonationBanner>
                        </StoreProvider>
                    </PearlToastProvider>
                </ApiLoadingProvider>
            </PearlThemeProvider>
        </RealtimeProvider>
    );
}
