import React, { useMemo } from 'react';
import { GhostButton, SolidButton } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';

const StoreList = ({ stores, selectedStore, onStoreClick, showButton = true }) => {
    const filteredStores = useMemo(() => {
        return stores.filter((store) => !isNaN(store.distance));
    }, [stores]);

    const { t } = useTranslation();
    return (
        <>
            <div className="prefferedStoresText">
                <p className="storeLocatorTitle">{t('Forms.EditAbout.PrefferedStore')}</p>
                <p className="resultsText">{t('Forms.EditAbout.Results')}</p>
            </div>
            <div className="storesListContainer">
                {filteredStores.map((store) => {
                    const postal_code = store.postal_code.split('-');
                    return (
                        <div
                            key={store.store_number}
                            className={`storeComponent ${
                                selectedStore === store.store_number ? 'selectedStoreStyle' : ''
                            } `}
                        >
                            <p className="storeNameStyle">{store.store_name}</p>
                            <p className="storeAddressStyle">{store.address}</p>
                            <p className="storeAddressStyle">
                                {store.city}, {store.state_or_province}, {postal_code[0]}
                            </p>
                            <p className="storeDistanceStyle">
                                {store?.distance.toFixed(1)} {t('Forms.EditAbout.MilesAway')}
                            </p>
                            {selectedStore === store.store_number && (
                                <GhostButton className="currentStoreStyle">
                                    {t('Forms.EditAbout.SelectedStore')}
                                </GhostButton>
                            )}
                            {showButton && selectedStore !== store.store_number && (
                                <SolidButton onClick={() => onStoreClick(store.store_number)}>
                                    {t('Forms.EditAbout.MakeThisMyStore')}
                                </SolidButton>
                            )}
                        </div>
                    );
                })}
            </div>
            <p className="doubleCheckTextStyle">{t('Forms.EditAbout.DoubleCheckText')}</p>
        </>
    );
};

export { StoreList };
