import styled from 'styled-components';

const VendorDBStoreStyled = styled.div`
    .checkmark-style {
        display: flex;
        position: relative;
        right: -8px;
        bottom: 25px;
    }
    .store-description-container {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        background: #207c7b;
    }
    .description-style {
        color: #fff;
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
    }
    .current-store-style {
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.65px;
        text-transform: uppercase;
    }
    .store-name-style {
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        margin: 0px;
    }
    .store-address-style {
        color: #132122;
        font-family: 'Objektiv Mk2';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        margin: 0px;
    }
    .store-distance-style {
        color: #e41395;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 0px;
    }
    .store-container {
        display: flex;
        width: 274.667px;
        padding: 16px;
        gap: 12px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid #ec008c;
        background: #fff;
        box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);
        @media (max-width: 600px) {
            width: 90%;
        }
    }
    .calendar-style {
        margin-top: 8px;
        margin-left: 13px;
        @media (max-width: 600px) {
            margin-top: 16px;
            width: 35px;
        }
    }
    .db-icon-style {
        width: 25px;
        padding-top: 3px;
        color: white;
    }
    .premium-users-style {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        margin-top: 12px;
    }
    .change-location-style {
        color: #132122;
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
    }
`;
export default VendorDBStoreStyled;
