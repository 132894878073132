import styled from 'styled-components';

export const StoreLocatorStyled = styled.div`
    margin-bottom: 40px;
    .storeLocatorTitle {
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 112.5% */
        letter-spacing: 1.65px;
        text-transform: uppercase;
    }
    .resultsText {
        color: var(--Basic-DBI-Black, #132122);
        font-family: 'Objektiv Mk2';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 112.5% */
        letter-spacing: 1.65px;
        text-transform: uppercase;
        @media (max-width: 600px) {
            margin-top: 0px;
        }
    }
    .zipcodeContainer {
        display: flex;
        gap: 12px;
    }

    .useMyLocationBtn > div {
        width: max-content;
    }

    .zipCodeField,
    .zipCodeField > div > div {
        width: 100%;
        border-radius: 8px;
    }
    .submitIconStyle {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }
    .storesListContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        gap: 16px;
        @media (max-width: 1100px) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        button {
            width: -webkit-fill-available;
        }
    }
    .storeComponent {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid #e0e2e2;
        box-shadow: 0px 2px 6px 0px rgba(19, 33, 34, 0.08);
        display: grid;
        grid-template-rows: auto 1fr auto;
    }
    .storeNameStyle {
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 0px;
    }
    .storeAddressStyle {
        color: #132122;
        font-family: 'Objektiv Mk2';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        margin: 0px;
    }
    .nearestStoreAddressStyle {
        color: #132122;
        font-family: 'Objektiv Mk2';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        margin: 0px;
        max-height: 44px;
    }
    .storeDistanceStyle {
        color: #e41395;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 0px;
    }
    .currentStoreStyle {
        border: 1px solid black;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background: none;
        }
    }
    .prefferedStoresText {
        display: flex;
        gap: 10px;
        margin: 0px;
        @media (max-width: 600px) {
            flex-direction: column;
            gap: 0px;
        }
    }
    .doubleCheckTextStyle {
        color: #e8184c;
        font-family: 'Objektiv Mk2';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-top: 50px;
    }
    .selectedStoreStyle {
        border: 1px solid #ec008c;
    }
`;
