import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    top: 0;
    z-index: 1;
    background-color: ${(props) => props.theme.colors.background.base};
    scrollbar-width: none;

    .linksContainer {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }

    button,
    button label,
    button label b {
        cursor: pointer;
    }

    button {
        background-color: transparent;
        border: 0;
        padding: 13.5px;
        margin: 0px 40px;
    }

    @media (max-width: 1400px) {
        button {
            margin: 0px 1%;
            padding: 13.5px 2%;
        }

        .linksContainer {
            margin: 0 8%;
        }
    }

    @media (max-width: 925px) {
        button {
            margin: 0;
            padding: 13.5px 2.5%;
        }

        .linksContainer {
            margin: 0;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .active {
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: 3px solid ${(props) => props.theme.colors.primary.base};

        > label {
            margin-bottom: -3px;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        padding: unset;
        overflow-x: auto;
        overscroll-behavior-x: auto;
        -ms-overflow-style: none;

        .linksContainer {
            margin: 0 16px;
            column-gap: 12px;
            justify-content: flex-start;
        }
    }

    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        .linksContainer {
            width: 80%;
            min-width: 740px;
            justify-content: flex-start;
        }
    }

    @media (min-width: 1400px) {
        .linksContainer {
            width: 60%;
            min-width: 1130px;
        }
    }

    @media (max-width: 600px) {
        margin-top: 75px;
    }
`;
