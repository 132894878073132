const WebstoreSectionTypes = {
    AMENITIESANDSERVICES: 'amenities',
    ABOUT: 'about',
    YOUR_DAVID_BRIDAL_STORE: 'dbstore',
    GALLERY: 'gallery',
    AVAILABILITY_CALENDAR: 'availability',
    REVIEWS: 'reviews',
    PACKAGES: 'packages',
    FAQS: 'faqs',
    PARTNERS: 'partners',
    AWARDS: 'awards',
};

export { WebstoreSectionTypes };
