import { makeAutoObservable } from 'mobx';
import { WebstoreSectionTypes, categoryExternalIds } from 'framework';

const initialValues = {};

export default class WebstoreStore {
    Id = null;
    VendorId = null;

    WebstoreName = null;
    VendorCategoryId = null;
    BusinessYears = null;
    VendorSubCategoryIds = null;
    VendorCategoryExternalId = null;
    VendorCategoryName = null;
    VendorSubCategoryNames = null;
    WebstoreUrl = null;
    ServiceAddressIsBusinessAddress = null;
    Travels = false;
    TravelOption = null;
    PhysicalStore = null;
    DisplayStoreAddress = false;
    DisplayPriceTier = true;
    OnlineStore = false;
    ShippingRange = null;
    ServiceTypes = null;
    ServiceVibes = null;
    Styles = null;
    SelectedPriceTier = null;
    SelectedPlan = null;
    SelectedPlanValue = '';
    CardHolderName = '';
    BusinessSummary = null;
    Facebook = null;
    Google = null;
    Instagram = null;
    Pinterest = null;
    SoundCloud = null;
    Spotify = null;
    TikTok = null;
    Twitter = null;
    Vimeo = null;
    LoveStoriesTV = null;
    Website = null;
    Yelp = null;
    YouTube = null;
    Media = null;
    Availability = [];
    Capacity = null;
    Packages = [];
    Faqs = [];
    Partners = [];
    Awards = [];
    PublishValidation = null;
    CurrentSetupStep = null;
    SelectedHeroImage = null;
    DashboardHeroImage = null;
    HasBeenPublished = false;
    HasUnpublishedChanges = false;
    LastPublishedTime = null;
    GooglePlaceId = null;
    IsAssignedSeat = false;
    EmailOptIn = false;
    ActiveFeatures = {};
    OptionalSections = [];
    SubscriptionTier = null;
    FavouriteStore = null;
    ZipcodeNearFavouriteStore = null;
    Created = null;
    Sections = [
        {
            key: WebstoreSectionTypes.GALLERY,
            previewPosition: 5,
        },
        {
            key: WebstoreSectionTypes.ABOUT,
            previewPosition: 1,
        },
        {
            key: WebstoreSectionTypes.AMENITIESANDSERVICES,
            previewPosition: 2,
        },
        {
            key: WebstoreSectionTypes.YOUR_DAVID_BRIDAL_STORE,
            previewPosition: 3,
        },
        {
            key: WebstoreSectionTypes.AVAILABILITY_CALENDAR,
            previewPosition: 4,
        },
        {
            key: WebstoreSectionTypes.REVIEWS,
            previewPosition: 6,
        },
        {
            key: WebstoreSectionTypes.PACKAGES,
            previewPosition: 7,
        },
        {
            key: WebstoreSectionTypes.FAQS,
            previewPosition: 8,
        },
        {
            key: WebstoreSectionTypes.PARTNERS,
            previewPosition: 9,
        },
        {
            key: WebstoreSectionTypes.AWARDS,
            previewPosition: 10,
        },
    ];

    Settings = null;
    OnlineAllowed = false;
    Loading = false;
    Addresses = [];
    IsCYB = false;
    CybContactEmail = null;
    DraftWebstoreId = null;
    props = {
        KPIs: {
            monthly: 0,
            weekly: 0,
            daily: 0,
            weeklySearchTotal: 0,
            monthlySearchTotal: 0,
            quarterlySearchTotal: 0,
            weeklySearchAppearances: 0,
            monthlySearchAppearances: 0,
            quarterlySearchAppearances: 0,
            inquiriesWeek: 0,
            inquiriesMonth: 0,
            inquiriesQuarter: 0,
        },
    };

    constructor(options) {
        for (const [k, v] of Object.entries(this)) {
            initialValues[k] = v;
        }

        makeAutoObservable(this);
    }

    updateWebstore(update) {
        for (const [key, value] of Object.entries(update)) {
            if (this.hasOwnProperty(key)) {
                this[key] = value;
            }
        }
    }

    setDefaults() {
        for (const [k, v] of Object.entries(initialValues)) {
            this[k] = v;
        }
    }

    updateSettings(settingCategory, setting) {
        let updatedCategory;
        if (this.Settings && this.Settings[settingCategory]) {
            updatedCategory = {
                [settingCategory]: {
                    ...this.Settings[settingCategory],
                    ...setting,
                },
            };
        } else {
            updatedCategory = {
                [settingCategory]: {
                    ...setting,
                },
            };
        }

        this.Settings = { ...this.Settings, ...updatedCategory };
    }

    clear() {
        this.setDefaults();
    }

    get forApi() {
        const data = {
            id: this.Id,
            vendorId: this.VendorId,
            name: this.WebstoreName,
            categoryId: this.VendorCategoryId,
            businessYears: this.BusinessYears,
            subCategoryIds: this.VendorSubCategoryIds,
            url: this.WebstoreUrl,
            serviceAddressIsBusinessAddress: this.ServiceAddressIsBusinessAddress,
            travels: this.Travels,
            travelOption: this.TravelOption,
            physicalStore: this.PhysicalStore,
            displayStoreAddress: this.DisplayStoreAddress,
            displayPriceTier: this.DisplayPriceTier,
            onlineStore: this.OnlineStore,
            shippingOption: this.ShippingRange,
            serviceTypes: this.ServiceTypes,
            serviceVibes: this.ServiceVibes,
            styles: this.Styles,
            priceTier: this.SelectedPriceTier,
            plan: this.SelectedPlan,
            businessDescription: this.BusinessSummary,
            links: this.SocialLinks,
            capacity: this.Capacity,
            packages: this.Packages,
            faqs: this.Faqs,
            partners: this.Partners,
            awards: this.Awards,
            optionalSections: this.OptionalSections,
            googlePlaceId: this.GooglePlaceId,
            emailOptIn: false,
            props: {
                currentSetupStep: this.CurrentSetupStep,
                selectedHeroImage: this.SelectedHeroImage,
                onlineAllowed: this.OnlineAllowed,
                lastPublishedTime: this.LastPublishedTime,
            },
            settings: this.Settings,
            addresses: this.Addresses,
            kpis: this.KPIs,
        };

        return data;
    }

    get forPatchBase() {
        const data = {
            id: this.Id,
            vendorId: this.VendorId,
        };

        return data;
    }

    get forPatchSetupStep() {
        const data = {
            ...this.forPatchBase,
            props: {
                currentSetupStep: this.CurrentSetupStep,
                selectedHeroImage: this.SelectedHeroImage,
            },
        };

        return data;
    }

    setFromApi(data) {
        this.Id = data.id;
        this.VendorId = data.vendorId;
        this.WebstoreName = data.name;
        this.VendorCategoryId = data.categoryId;
        this.BusinessYears = data.businessYears;
        this.VendorSubCategoryIds = data.subCategoryIds;
        this.WebstoreUrl = data.url;
        this.ServiceAddressIsBusinessAddress = data.serviceAddressIsBusinessAddress;
        this.Travels = data.travels;
        this.TravelOption = data.travelOption;
        this.PhysicalStore = data.physicalStore;
        this.DisplayStoreAddress = data.displayStoreAddress;
        this.DisplayPriceTier = data.displayPriceTier;
        this.OnlineStore = data.onlineStore;
        this.ShippingRange = data.shippingOption;
        this.ServiceTypes = data.serviceTypes;
        this.ServiceVibes = data.serviceVibes;
        this.Styles = data.styles;
        this.SelectedPriceTier = data.priceTier;
        this.SelectedPlan = data.plan;
        this.BusinessSummary = data.businessDescription;
        this.Media = data.media;
        this.DashboardHeroImage = data.dashboardHeroImage;
        this.Packages = data.packages;
        this.Faqs = data.faqs;
        this.Partners = data.partners;
        this.Awards = data.awards;
        this.HasBeenPublished = data.hasBeenPublished;
        this.HasUnpublishedChanges = data.hasUnpublishedChanges;
        this.Packages = data.packages;
        this.OptionalSections = data.optionalSections;
        this.GooglePlaceId = data.googlePlaceId;
        this.IsAssignedSeat = data.isAssignedSeat;
        this.ActiveFeatures = data.activeFeatures;
        this.PartnerAssociations = data.partnerAssociations;
        if (data.links) {
            this.setSocialLinks(data.links);
        }
        this.Capacity = data.capacity === 0 ? null : data.capacity;
        if (data.publishValidation) {
            this.PublishValidation = data.publishValidation;
        } else {
            this.PublishValidation = null;
        }
        if (data.props) {
            this.CurrentSetupStep = data.props.currentSetupStep;
            this.SelectedHeroImage = data.props.selectedHeroImage || 0;
            this.OnlineAllowed = data.props.onlineAllowed;
            this.LastPublishedTime = data.props.lastPublishedTime
                ? new Date(data.props.lastPublishedTime)
                : '';
        }
        this.EmailOptIn = false;
        this.PartnerAssociations = data.partnerAssociations;
        if (data.settings) {
            this.Settings = { ...this.Settings, ...data.settings };
        }
        this.SubscriptionTier = data.subscriptionTier?.toLowerCase();
        this.Addresses = data.addresses;
        this.IsCYB = data.isCYB;
        this.CybContactEmail = data.cybContactEmail;
        this.DraftWebstoreId = data.draftWebstoreId;
        this.FavouriteStore = data.favouriteStore;
        this.ZipcodeNearFavouriteStore = data.zipcodeNearFavouriteStore;
        this.Created = data.created;
    }
    get SocialLinks() {
        const links = [];
        if (this.Website) {
            links.push({ socialMediaName: 'Website', link: this.Website });
        }
        if (this.YouTube) {
            links.push({ socialMediaName: 'YouTube', link: this.YouTube });
        }
        if (this.Google) {
            links.push({ socialMediaName: 'Google', link: this.Google });
        }
        if (this.Pinterest) {
            links.push({ socialMediaName: 'Pinterest', link: this.Pinterest });
        }
        if (this.Yelp) {
            links.push({ socialMediaName: 'Yelp', link: this.Yelp });
        }
        if (this.Twitter) {
            links.push({ socialMediaName: 'Twitter', link: this.Twitter });
        }
        if (this.Facebook) {
            links.push({ socialMediaName: 'Facebook', link: this.Facebook });
        }
        if (this.TikTok) {
            links.push({ socialMediaName: 'TikTok', link: this.TikTok });
        }
        if (this.Instagram) {
            links.push({ socialMediaName: 'Instagram', link: this.Instagram });
        }
        if (this.Vimeo) {
            links.push({ socialMediaName: 'Vimeo', link: this.Vimeo });
        }
        if (this.Spotify) {
            links.push({ socialMediaName: 'Spotify', link: this.Spotify });
        }
        if (this.SoundCloud) {
            links.push({
                socialMediaName: 'SoundCloud',
                link: this.SoundCloud,
            });
        }
        if (this.LoveStoriesTV) {
            links.push({ socialMediaName: 'LoveStoriesTV', link: this.LoveStoriesTV });
        }
        return links;
    }

    setSocialLinks(links) {
        links.forEach((link) => {
            this[link.socialMediaName] = link.link;
        });
    }

    setLoading(value) {
        this.Loading = value;
    }

    setVendorCategoryName(value) {
        this.VendorCategoryName = value;
    }

    setVendorCategoryExternalId(value) {
        this.VendorCategoryExternalId = value;
    }

    setVendorSubCategoryName(value) {
        this.VendorSubCategoryName = value;
    }

    setAvailability(availability) {
        this.Availability = availability;
    }

    addPartner(partners) {
        this.Partners = partners;
    }

    setPartnerToAdd(partner) {
        this.PartnerToAdd = partner;
    }

    get sortedPreviewSections() {
        return this.Sections.sort((a, b) => a.previewPosition - b.previewPosition);
    }

    get hasCapacity() {
        const capacityCategories = [categoryExternalIds.lodging, categoryExternalIds.venues];
        return capacityCategories.includes(this.VendorCategoryExternalId);
    }

    get stripePlanName() {
        return this.SelectedPlan;
    }

    packagesFromEditForm(packages) {
        const packageArray = Object.values(packages);
        return packageArray.map((p, i) => {
            return {
                id: p.Id,
                webstoreId: this.Id,
                name: p.Name,
                price: p.Price,
                description: p.Description,
                inclusions: p.Inclusions,
                ordinal: i,
                image: p.Image,
                open: p.Open,
                isAdded: false,
            };
        });
    }

    packagesForEditForm() {
        const mapped = this.Packages.map((p) => {
            return {
                Id: p.id,
                Name: p.name,
                Price: p.price,
                Description: p.description,
                Inclusions: p.inclusions,
                Image: p.image,
                Ordinal: p.ordinal,
                Open: p.open,
                ValidationResult: p.validationResult,
                IsAdded: false,
            };
        });
        return Object.assign({}, mapped);
    }

    awardsFromEditForm(awards) {
        const awardsArray = Object.values(awards);
        return awardsArray.map((a, i) => {
            return {
                id: a.Id,
                webstoreId: this.Id,
                name: a.Name,
                link: a.Link,
                ordinal: i,
                image: a.Image,
                open: a.Open,
            };
        });
    }

    awardsForEditForm() {
        const mappedAwards = this.Awards.map((a) => {
            return {
                Name: a.name,
                Link: a.link,
                Image: a.image,
                Ordinal: a.ordinal,
                ValidationResult: a.validationResult,
                Open: a.open,
            };
        });
        return Object.assign({}, mappedAwards);
    }
}
