import styled from 'styled-components';

const PriceTierSectionEditStyled = styled.section`
    .priceTierToggle {
        display: flex;
        align-items: center;
    }
    .priceTierToggleTitle {
        font-size: 14px;
        line-height: 16.8px;
    }
`;

export { PriceTierSectionEditStyled };
