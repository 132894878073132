import { IconName, LinkButton, SolidButton } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WebstoreSectionTypes } from 'framework';
import { EditSectionLayoutStyled } from './EditSectionLayout.styled';

const EditSectionLayout = (props) => {
    const {
        children,
        disableSaveButton,
        hideSaveButton,
        sectionName,
        showConfirmButton,
        hideConfirmButton,
    } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBack = () => {
        navigate(-1, {
            state: {
                scrollToSection: sectionName,
            },
        });
    };

    return (
        <EditSectionLayoutStyled>
            <div className="top">
                <LinkButton
                    data-testid="back-button-link"
                    className={'back-button'}
                    startIcon={IconName.ARROW_LEFT}
                    variant="accent"
                    onClick={handleBack}
                >
                    {t('EditBack')}
                </LinkButton>
                <main
                    className={sectionName === WebstoreSectionTypes.GALLERY ? 'mediaSection' : ''}
                >
                    {children}
                </main>
            </div>
            <div className="footer">
                <LinkButton className={'back-button'} variant="accent" onClick={handleBack}>
                    {t('EditBack')}
                </LinkButton>
                {!hideSaveButton && (
                    <SolidButton
                        type="submit"
                        className={'save-button'}
                        disabled={disableSaveButton}
                        endIcon={IconName.CHECKMARK}
                    >
                        {disableSaveButton ? t('Forms.Buttons.AutoSaved') : t('Forms.Buttons.Save')}
                    </SolidButton>
                )}
                {showConfirmButton && (
                    <SolidButton
                        type="submit"
                        className={'save-button'}
                        disabled={hideConfirmButton}
                    >
                        {t('Forms.Buttons.ConfirmMyStore')}
                    </SolidButton>
                )}
            </div>
        </EditSectionLayoutStyled>
    );
};

EditSectionLayout.defaultProps = {
    disableSaveButton: false,
};

export { EditSectionLayout };
