import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../shared/constants/navigation';
import { StripeService } from '../lib/StripeService/StripeService';
import { usePearlToast } from '../../framework';
import { useTranslation } from 'react-i18next';
import { Capacitor, Plugins } from '@capacitor/core';
import { VendorService } from '../lib/apis/VendorService';

const { Browser } = Plugins;

const useUpgradeSubscription = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { toastError } = usePearlToast();
    const vendorApi = VendorService();
    const { createCustomerPortalSession } = StripeService();

    const [isCreatingSession, setIsCreatingSession] = useState(false);

    const upgrade = async (vendor) => {
        // admins aren't allowed to upgrade
        if (location.pathname.startsWith(APP_ROUTES.admin.root)) {
            toastError(t('Admin.ActionUnavailable'));
            return;
        }

        // need to accept tos before subscribing
        if (!vendor.tosAccepted) {
            navigate(APP_ROUTES.vendor.termsOfService);
            return;
        }

        setIsCreatingSession(true);

        // determine if webstore is already a subscriber
        // checking again here should cut down on stripe webhook race conditions
        let isStripeCustomer;
        try {
            isStripeCustomer = await vendorApi.isStripeCustomerById(vendor.id);
        } catch {
            // should only reach this block on api call error
            // can't proceed with upgrade, we don't know if they already
            // have a subscription and don't want to create duplicates
            toastError(t('Stripe.CreatingStripeSessionError'));
            setIsCreatingSession(false);
            return;
        }

        // should know by this point if they 'definitely' are or are not a
        // current stipe customer
        if (!isStripeCustomer) {
            // if not, initial subscription pricing page
            navigate(APP_ROUTES.vendor.prices);
        } else {
            // if they are a customer, open a portal session for them
            try {
                const sessionUrl = await createCustomerPortalSession(vendor.id);

                if (sessionUrl) {
                    if (Capacitor.isNativePlatform()) {
                        Browser.open({ url: sessionUrl });
                    } else {
                        const win = window.open(sessionUrl);
                        win?.focus();
                    }
                } else {
                    toastError(t('Stripe.CreatingStripeSessionError'));
                }
            } catch (err) {
                const message =
                    err.cause?.status === 403
                        ? err.cause?.message
                        : t('Stripe.CreatingStripeSessionError');
                toastError(message);
            } finally {
                setIsCreatingSession(false);
            }
        }
    };

    return {
        upgrade,
        isCreatingSession,
    };
};

export { useUpgradeSubscription };
