import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/navigation';
import { SolidButton } from 'dbi-pearl-ui-kit';
import { TokenService } from '../../../lib/apis/TokenService';

const StyledImpersonationBanner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    padding: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    box-shadow: inset 0 0 0 3px ${(props) => props.theme.colors.error.base};
    z-index: 9999;
    pointer-events: none;

    display: ${(props) => (props.showBanner ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;

    & > div {
        display: flex;
        justify-content: space-between;

        & > .banner-label {
            padding: 0 4px;
            background: ${(props) => props.theme.colors.error.base};
            color: white;
        }

        & > button {
            pointer-events: initial;
            padding: 0;
            height: unset;
            background: ${(props) => props.theme.colors.error.base};
        }
    }
`;

const AdminLabel = () => <span className="banner-label banner-label-admin">admin</span>;
const UserLabel = ({ name }) => (
    <span className="banner-label banner-label-user">{`impersonating user: ${name}`}</span>
);

const ImpersonationBanner = ({ children }) => {
    const tokenService = TokenService();
    const navigate = useNavigate();
    const userAttributes = tokenService.getUserAttributes();
    const hasAdminId = !!userAttributes?.adminData?.id;
    const [showBanner, setShowBanner] = useState(hasAdminId);

    useEffect(() => {
        setShowBanner(hasAdminId);
    }, [hasAdminId]);

    const logout = useCallback(() => navigate(APP_ROUTES.logout), [navigate]);

    return (
        <>
            <StyledImpersonationBanner data-testid="impersonation-banner" showBanner={showBanner}>
                <div>
                    <AdminLabel />
                    <UserLabel name={userAttributes.name} />
                    <SolidButton type="button" onClick={logout}>
                        Exit
                    </SolidButton>
                </div>
                <div>
                    <AdminLabel />
                    <UserLabel name={userAttributes.name} />
                    <AdminLabel />
                </div>
            </StyledImpersonationBanner>
            {children}
        </>
    );
};

export { ImpersonationBanner };
