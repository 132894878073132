import React, { useEffect, useRef, useState } from 'react';
import { IconSizes, LinkButton, PearlIcon, TextInput } from 'dbi-pearl-ui-kit';
import { StoreLocatorStyled } from './DbiStoreLocator.style';
import { SystemService } from 'lib/apis/SystemService';
import { GeocodeService } from 'lib/apis/GeocodeService';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StoreList } from './StoreList';
import { PearlError } from 'framework';
import { StorageService } from 'lib/StorageService';

export const getDistance = ({ currentLocation, targetLocation }) => {
    const p = 0.017453292519943295; // Math.PI / 180
    const cos = Math.cos;
    const a =
        0.5 -
        cos((targetLocation.lat - currentLocation.lat) * p) / 2 +
        (cos(currentLocation.lat * p) *
            cos(targetLocation.lat * p) *
            (1 - cos((targetLocation.lng - currentLocation.lng) * p))) /
            2;

    return 7917.6 * Math.asin(Math.sqrt(a)); // 2 * R; R = 3958.8 (radius of Earth in miles)
};
export const getClosestStores = async (currentLoc, dbiStoresList) => {
    const storesWithDistance = dbiStoresList?.map((targetLoc) => {
        const distance = getDistance({
            currentLocation: {
                lat: currentLoc?.latitude,
                lng: currentLoc?.longitude,
            },
            targetLocation: { lat: targetLoc?.lat, lng: targetLoc?.lng },
        });

        return { ...targetLoc, distance };
    });

    const sortedStores = storesWithDistance.sort((a, b) => a.distance - b.distance);

    return sortedStores.slice(0, 3);
};
const DbiStoreLocator = ({
    setFavouriteStore,
    setZipcodeNearFavouriteStore,
    dbiStoresList,
    setNewZipcode,
    setDisableSave,
}) => {
    const systemService = SystemService();
    const geocodeService = GeocodeService();
    const storageService = StorageService();
    const { t } = useTranslation();
    const { setValue, getValues, watch, clearErrors, setError } = useFormContext();
    const zipcode = getValues('YourZipCode');
    const watchZipcode = watch('YourZipCode');
    const [closestStores, setClosestStores] = useState(null);
    const [selectedStore, setSelectedStore] = useState(false);
    const prevZipcode = useRef('');

    useEffect(() => {
        const storedClosestStores = storageService.get('closestStores');
        const storedSelectedStore = storageService.get('selectedStore');

        if (storedClosestStores) {
            setClosestStores(JSON.parse(storedClosestStores));
        }
        if (storedSelectedStore) {
            setSelectedStore(JSON.parse(storedSelectedStore));
            setFavouriteStore(JSON.parse(storedSelectedStore));
        }
    }, []);

    const handleZipcode = async (e) => {
        e.stopPropagation();
        const currentLoc = await geocodeService.getGeocodeByZipcode(zipcode);
        if (currentLoc) {
            const closestStores = await getClosestStores(currentLoc, dbiStoresList);
            setClosestStores(closestStores);
            storageService.set('closestStores', JSON.stringify(closestStores));
            clearErrors();
        } else {
            setError('YourZipCode', {
                type: 'manual',
                message: 'Please enter a valid zipcode',
            });
            setClosestStores(null);
            setDisableSave(true);
        }
    };
    const handleStoreClick = async (favouriteStore) => {
        setSelectedStore(favouriteStore);
        setFavouriteStore(favouriteStore);
        storageService.set('selectedStore', JSON.stringify(favouriteStore));
        setZipcodeNearFavouriteStore(zipcode);
    };

    const getCurrentLocation = async () => {
        let loc;

        try {
            const whereIAm = await systemService.getWhereAmI();
            if (whereIAm?.latitude && whereIAm?.longitude) {
                loc = await geocodeService.getReverseGeoCode(whereIAm.latitude, whereIAm.longitude);
                setValue('YourZipCode', loc.zipcode);

                const currentLoc = await geocodeService.getGeocodeByZipcode(loc.zipcode);
                if (currentLoc) {
                    const closestStores = await getClosestStores(currentLoc, dbiStoresList);
                    setClosestStores(closestStores);
                    clearErrors();
                } else {
                    setError('YourZipCode', {
                        type: 'manual',
                        message: 'Please enter a valid zipcode',
                    });
                    setClosestStores(null);
                    setDisableSave(true);
                }
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    useEffect(() => {
        if (!watchZipcode || watchZipcode?.length < prevZipcode?.current?.length) {
            setClosestStores(null);
            setFavouriteStore(null);
            setSelectedStore(false);
            setDisableSave(false);
            clearErrors();
        }
        prevZipcode.current = watchZipcode;
        setNewZipcode(watchZipcode);
    }, [dbiStoresList, watchZipcode]);

    return (
        <StoreLocatorStyled>
            <p className="storeLocatorTitle">{t('Forms.EditAbout.StoreLocatorTitle')}</p>
            <p>{t('Forms.EditAbout.StoreLocatorSubTitle')}</p>
            <div className="zipcodeContainer">
                <div className="zipCodeField" style={{ position: 'relative' }}>
                    <TextInput formFieldName="YourZipCode" />
                    <PearlError name="YourZipCode" />
                    <PearlIcon
                        iconName="chevron-right"
                        size={IconSizes.MD}
                        onClick={handleZipcode}
                        className="submitIconStyle"
                        data-testid="chevron-right"
                    />
                </div>
                <LinkButton
                    className="useMyLocationBtn"
                    children={'Use My Location'}
                    onClick={getCurrentLocation}
                />
            </div>
            {closestStores && (
                <StoreList
                    stores={closestStores}
                    selectedStore={selectedStore}
                    onStoreClick={handleStoreClick}
                />
            )}
        </StoreLocatorStyled>
    );
};
export default DbiStoreLocator;
