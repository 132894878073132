import React, { useMemo } from 'react';
import WebstoreNavigationStyled from './WebstoreNavigation.styled';
import { useTranslation } from 'react-i18next';

function WebstoreNavigation(props) {
    const { links, scrollTo, hideReviews, hideDBStore = false } = props;
    const { t } = useTranslation();
    const filteredLinks = useMemo(() => {
        return links?.filter(
            (section) =>
                !(
                    (hideReviews && section.section === 'reviews') ||
                    (hideDBStore && section.section === 'dbstore')
                )
        );
    }, [links, hideReviews]);

    return (
        <WebstoreNavigationStyled id="webstore-navigation" data-testid="webstore-navigation">
            <div className="linksContainer">
                {filteredLinks?.map((section, index) => {
                    return (
                        <button
                            data-testid={`${section.anchorId}`}
                            id={`${section.anchorId}-button`}
                            aria-label={`${t(`Navigation.AriaLabel`)} ${t(
                                `Navigation.WebstoreNavigation.${section.displayName}`
                            )}`}
                            key={index}
                            type="button"
                            onClick={() => scrollTo(section.anchorId)}
                        >
                            <label>
                                <b>{t(`Navigation.WebstoreNavigation.${section.displayName}`)}</b>
                            </label>
                        </button>
                    );
                })}
            </div>
        </WebstoreNavigationStyled>
    );
}

export default WebstoreNavigation;
