import React, { createContext, useState, useContext } from 'react';

const ApiLoaderContext = createContext({
    loading: null,
    setLoading: () => {},
});

export const useApiloader = () => {
    return useContext(ApiLoaderContext);
};

export const ApiLoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(null);

    return (
        <ApiLoaderContext.Provider value={{ loading, setLoading }}>
            {children}
        </ApiLoaderContext.Provider>
    );
};
