import React from 'react';
import { ConversationListStyled } from './ConversationList.styled';

const ConversationList = (props) => {
    const { className, children } = props;

    return (
        <ConversationListStyled className={`${className || ''}`} data-testid="conversation-list">
            {children}
        </ConversationListStyled>
    );
};

export { ConversationList };
