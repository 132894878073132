import { APP_ROUTES } from '../shared/constants/navigation';
import { StorageKeys, StorageService } from '../lib/StorageService';

const RequireLogin = (context) => {
    if (!context?.store?.authUser?.isAuthed) {
        const pathname = window.location.pathname;
        if (pathname) {
            const storageService = StorageService();
            storageService.set(StorageKeys.internalCallbackUrl, pathname);
        }
        return APP_ROUTES.vendor.auth.login;
    } else {
        return 'next';
    }
};

export { RequireLogin };
