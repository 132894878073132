import React from 'react';
import { WelcomeScreenContainer, Container, RotatedImage, NormalImage } from './SuccessPage.Styled';
import WelcomeImage from 'assets/welcome.png';
import PearlByDavids from 'assets/pearl-by-davids.png';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SuccessPage = ({ children }) => {
    const { webstore } = useWebstoreContext();
    const { t } = useTranslation();

    const ClaimedBusinessText = styled.span`
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
    `;

    const WebstoreName = styled.div`
        margin-bottom: 100px;
    `;

    return (
        <WelcomeScreenContainer className="welcomeBannerContainer" data-testid="welcome-container">
            <Container>
                <RotatedImage src={WelcomeImage} alt="WelcomeImage" data-testid="welcome-image" />
                <NormalImage
                    src={PearlByDavids}
                    alt="PearlByDavids"
                    data-testid="pearl-by-davids"
                />
            </Container>
            <div className="messageBox">{children}</div>
            {webstore?.WebstoreName !== null && (
                <WebstoreName data-testid="webstore-name">
                    The{' '}
                    <ClaimedBusinessText>
                        <b>{webstore?.WebstoreName}</b>{' '}
                    </ClaimedBusinessText>
                    {t('Forms.VendorPaymentSuccess.ClaimedBusinessText')}
                </WebstoreName>
            )}
        </WelcomeScreenContainer>
    );
};

export default SuccessPage;
