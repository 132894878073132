import styled from 'styled-components';

const PearlAlertBannerStyled = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;

    padding: 27px 48px;
    background-color: ${(props) => {
        if (props.type === 'error') {
            return props.theme.colors.error.base;
        }
        if (props.type === 'success') {
            return props.theme.colors.success.base;
        }
    }};
    color: ${(props) => props.theme.colors.primary.contrast};

    .alert-header-row {
        display: flex;
        justify-content: space-evenly;
    }

    .alert-body {
        flex-grow: 2;
        margin-left: 16px;
        text-transform: uppercase;
    }

    .close-alert {
        padding: 0;
        background: none;
        border: none;
        color: ${(props) => props.theme.colors.primary.contrast};
        cursor: pointer;
        height: 16px;
        width: 16px;
    }

    a,
    a:visited {
        color: unset;
    }
`;

export { PearlAlertBannerStyled };
