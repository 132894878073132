import styled from 'styled-components';

const HamburgerUserMenuStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    color: ${(props) => props.theme.colors.foreground.text};
    background-color: #faf4ef;
    padding: 24px 16px 8px 16px;
    border-top: 1px solid ${(props) => props.theme.colors.background.base};
    align-items: start;
    width: auto;

    .non-authed-user-menu {
        .user-menu-header {
            h4 {
                margin-bottom: 0;
            }
            p {
                margin-top: 6px;
            }
        }
        .login-section-wrapper {
            display: flex;
            justify-content: center;
            .p1 {
                display: inline-flex;
                align-items: center;
            }
        }
        .sign-up-button {
            width: 100%;
            margin-top: 18px;
        }
        .hamburger-user-menu-login-button {
            padding-bottom: 0;

            label {
                padding-bottom: 0px;
                margin-left: 8px;
            }
            b {
                text-transform: lowercase;
            }
        }
    }

    .greeting {
        word-break: break-word;
        margin: 0px;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 1.5px;
    }

    .link-style {
        padding-left: 25px;
        a {
            text-decoration: none;

            p {
                color: #132122;
                font-family: 'Objektiv Mk2';
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                text-transform: capitalize;
                line-height: 24px;
            }
        }
    }

    .sign-inout-style {
        margin: 0px;
        span {
            text-decoration: none;
            color: #132122 !important;
            font-family: 'Objektiv Mk2 Bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    .non-authed-container {
        display: flex;
        gap: 8px;
    }

    .user-icon-style {
        margin-top: 4px;
    }

    .hamburger-profile {
        display: flex;
        gap: 9px;
        color: black;
        text-decoration: none;
        cursor: pointer;
        font-family: 'Objektiv Mk2 Bold';
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
        -webkit-letter-spacing: 1.65px;
        -moz-letter-spacing: 1.65px;
        -ms-letter-spacing: 1.65px;
        letter-spacing: 1.65px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .greeting-container {
        display: flex;
        gap: 8px;
    }

    .no-capitalize {
        text-transform: none !important;
    }
`;

export { HamburgerUserMenuStyled };
