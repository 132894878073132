import { useTranslation } from 'react-i18next';
import { ReturnToBusinessLocationsButton } from './ReturnToBusinessLocationsButton';
import { AccountSetupStepCounter } from '../../../framework';
import React from 'react';
import styled from 'styled-components';
import { StorageKeys, StorageService } from 'lib/StorageService';
import { maskEmail } from 'shared/utils/utils';

const NewVendorWebstoreSetupHeader = (props) => {
    const {
        onReturnToBusinessLocations,
        stepName,
        currentStepCount,
        totalSteps,
        hideStepCount,
        showSubTitle,
    } = props;

    const { t } = useTranslation();
    const stepTns = t(`Forms.${stepName}`, { returnObjects: true });
    const storageService = StorageService();
    const cybInProgress = JSON.parse(storageService?.get(StorageKeys.cybInProgressWebstore));

    const Divider = styled.hr`
        border: none;
        border-top: 2px solid #cfd0d0;
        margin: 32px 0;
    `;
    const DynamicTextWrapper = styled.span`
        color: #132122;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
    `;

    return (
        <>
            <div className="actions">
                {!!onReturnToBusinessLocations && (
                    <ReturnToBusinessLocationsButton onClick={onReturnToBusinessLocations} />
                )}
            </div>
            <AccountSetupStepCounter
                stepCountText={`STEP ${t('Forms.Labels.Steps', {
                    completedSteps: currentStepCount,
                    totalSteps: totalSteps,
                })}`}
                stepNameText={stepTns.StepName}
                hideStepCount={hideStepCount}
            />
            <h1 className="title">{stepTns.Title}</h1>
            <p className="subtitle">
                {showSubTitle && cybInProgress?.IsCYB ? (
                    <>
                        {t('Forms.BasicDetails.DynamicSubTitle1')}{' '}
                        <DynamicTextWrapper>
                            <b>{maskEmail(cybInProgress?.CybContactEmail)}</b>
                        </DynamicTextWrapper>{' '}
                        {t('Forms.BasicDetails.DynamicSubTitle2')}
                    </>
                ) : (
                    stepTns.Subtitle
                )}
            </p>
            {!!stepTns.Requirement && (
                <div className="stepInstructions">
                    <label className="cta">
                        <b>{stepTns.Requirement}</b>
                    </label>
                </div>
            )}
            <Divider />
        </>
    );
};

export { NewVendorWebstoreSetupHeader };
