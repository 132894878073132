import React, { useState } from 'react';
import { PriceTierSection } from '../../PriceTierSection/PriceTierSectionNew';
import { PriceTierSectionEditStyled } from './PriceTierSectionEdit.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { ToggleSwitch } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';

const PriceTierSectionEdit = ({ defaultTierValue }) => {
    const { webstore } = useWebstoreContext();
    const [toggle, setToggle] = useState(webstore.DisplayPriceTier);
    const { setValue } = useFormContext();
    const { t } = useTranslation();
    return (
        <PriceTierSectionEditStyled data-testid="price-tier-section-edit">
            <SectionEditHeader
                title="Forms.PriceTier.StepName"
                description="Forms.PriceTier.EditSectionDescription"
            />
            <p className="priceTierToggleTitle">{t('Forms.PriceTier.DisplayToggleTitle')}</p>
            <div className="priceTierToggle">
                <ToggleSwitch
                    checked={webstore.DisplayPriceTier}
                    onChange={(e) => {
                        setToggle(e);
                        setValue('DisplayPriceTier', e);
                    }}
                    name={'DisplayPriceTier'}
                />
                <span>{toggle ? 'Yes' : 'No'}</span>
            </div>

            <PriceTierSection defaultTierValue={defaultTierValue} />
        </PriceTierSectionEditStyled>
    );
};

export { PriceTierSectionEdit };
