import { HamburgerUserMenuStyled } from './HamburgerUserMenu.styled';
import { useTranslation } from 'react-i18next';
import { IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { useStore } from 'stores/Store';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { openUserChoiceLoginModal } from '../../UserChoiceLogin/UserChoiceLogin';
import { usePearlModal, useIsScreenLarge } from 'framework';
import { AuthType } from '../../../../constants/authType';
import { navigation } from 'shared/constants/navigation';
import { getAppName } from 'shared/hooks/AppName';
import { Link } from 'react-router-dom';
import useWindowSize from 'vendor/hooks/useWindowSize';
import { useEffect, useMemo, useState } from 'react';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { StorageKeys, StorageService } from 'lib/StorageService';

const InternalLink = (props) => {
    const { link, translator } = props;

    const noCapitalize = [
        'Navigation.UserMenu.CustomerView.displayName',
        'Navigation.UserMenu.VendorView.displayName',
    ].includes(link.displayName);

    if (!link || !link.src) {
        console.error('Invalid link object: ', link);
        return null;
    }

    const displayText =
        link.displayName === 'Navigation.UserMenu.CustomerView.displayName'
            ? 'Switch to Customer View'
            : translator(link.displayName) &&
              link.displayName === 'Navigation.UserMenu.VendorView.displayName'
            ? 'Switch to Vendor View'
            : translator(link.displayName);

    return (
        <Link to={link.src} state={link.stateToSend}>
            <p className={noCapitalize ? 'no-capitalize' : ''}>{displayText}</p>
        </Link>
    );
};

const NonAuthedUserMenu = (props) => {
    const { drawerCloser } = props;

    const { t } = useTranslation();
    const { openModal, closeModal } = usePearlModal();
    const isScreenLarge = useIsScreenLarge();

    const loginClickHandler = (authType) => {
        openUserChoiceLoginModal(openModal, closeModal, isScreenLarge, authType);
        drawerCloser();
    };
    return (
        <div className="non-authed-user-menu">
            <div className="non-authed-container">
                <PearlIcon iconName="user" size={IconSizes.MD} className="user-icon-style" />
                <p className="sign-inout-style">
                    <span
                        role="button"
                        tabIndex="0"
                        onClick={() => loginClickHandler(AuthType.login)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                loginClickHandler(AuthType.login);
                            }
                        }}
                    >
                        {t('Navigation.HamburgerMenu.SignIn')}
                    </span>
                    <b>/</b>
                    <span
                        role="button"
                        tabIndex="0"
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => loginClickHandler(AuthType.signup)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                loginClickHandler(AuthType.signup);
                            }
                        }}
                    >
                        {t('Navigation.HamburgerMenu.SignUp')}
                    </span>
                </p>
            </div>
        </div>
    );
};

const VendorGreeting = () => {
    const { vendor } = useStore();
    const { t } = useTranslation();
    const appName = getAppName();
    const { webstore } = useWebstoreContext();

    const { width } = useWindowSize();
    const sections = useMemo(() => {
        return navigation.vendorUserHamburgerMenu
            .map((link) => {
                if (link.displayName === 'Navigation.UserMenu.CustomerView.displayName') {
                    return {
                        ...link,
                        src:
                            appName === 'vendor'
                                ? webstore.WebstoreUrl
                                    ? webstore.WebstoreUrl
                                    : '/dashboard'
                                : '/',
                    };
                }
                return link;
            })
            .filter((link) =>
                appName === 'event-user'
                    ? link.displayName !== 'Navigation.UserMenu.CustomerView.displayName'
                    : link.displayName !== 'Navigation.UserMenu.VendorView.displayName'
            );
    }, [appName]);

    return (
        <>
            <div className="greeting-container">
                <PearlIcon iconName="user" size={IconSizes.MD} />
                <h3 className="greeting">
                    {t('Navigation.HamburgerMenu.HiUser', {
                        firstName: vendor?.contactFirstName,
                    })}
                </h3>
            </div>
            {width < 815 &&
                sections.map((link) => {
                    return (
                        <ul key={link.src} className="link-style">
                            <InternalLink link={link} translator={t} className="link-style" />
                        </ul>
                    );
                })}
        </>
    );
};

const EventUserGreeting = () => {
    const { t } = useTranslation();
    const { eventUser } = useStore();
    const { width } = useWindowSize();
    const appName = getAppName();
    const storage = StorageService();
    const newVendor = storage.get(StorageKeys.newVendor);
    const updatedLinks = [...navigation.eventUserHamburgerMenu];
    const [sections, setSections] = useState(updatedLinks);

    useEffect(() => {
        if (newVendor === null) {
            const links = navigation.eventUserHamburgerMenu.filter(
                (link) =>
                    link.displayName !== 'Navigation.UserMenu.VendorView.displayName' &&
                    link.displayName !== 'Navigation.UserMenu.CustomerView.displayName'
            );
            setSections(links);
        }
        if (appName === 'vendor' && newVendor !== null) {
            const links = navigation.eventUserHamburgerMenu.filter(
                (link) => link.displayName !== 'Navigation.UserMenu.VendorView.displayName'
            );
            setSections(links);
        }
        if (appName === 'event-user' && newVendor !== null) {
            const links = navigation.eventUserHamburgerMenu.filter(
                (link) => link.displayName !== 'Navigation.UserMenu.CustomerView.displayName'
            );
            setSections(links);
        }
    }, [appName]);
    return (
        <>
            <div className="greeting-container">
                <PearlIcon iconName="user" size={IconSizes.MD} />
                <h3 className="greeting">
                    {t('Navigation.HamburgerMenu.HiUser', {
                        firstName: eventUser?.FirstName,
                    })}
                </h3>
            </div>
            {width < 815 &&
                sections.map((link) => {
                    return (
                        <ul key={link.src} className="link-style">
                            {link.src ? <InternalLink link={link} translator={t} /> : ''}
                        </ul>
                    );
                })}
        </>
    );
};

const AuthedUserMenu = () => {
    const { authUser } = useAuthUserContext();
    const appName = getAppName();

    return (
        <div>
            {authUser.isAuthedVendor && appName === 'vendor' && <VendorGreeting />}
            {authUser.isAuthedEventUser && appName === 'event-user' && <EventUserGreeting />}
        </div>
    );
};

const HamburgerUserMenu = (props) => {
    const { drawerCloser } = props;

    const { authUser } = useAuthUserContext();

    return (
        <HamburgerUserMenuStyled>
            {authUser.isAuthedVendor || authUser.isAuthedEventUser ? (
                <AuthedUserMenu />
            ) : (
                <NonAuthedUserMenu drawerCloser={drawerCloser} />
            )}
        </HamburgerUserMenuStyled>
    );
};

export { HamburgerUserMenu };
