import apisauce from 'apisauce';
import { PearlApiClient, PearlApps } from 'framework';
import { v4 as uuidv4 } from 'uuid';
import { unwrapResponse } from '../../../lib/apis/utils';

export const IMAGE_CATEGORIES = {
    GENERAL: 'general',
    PACKAGES: 'packages',
    AWARDS: 'awards',
    DASHBOARD_HERO: 'dashboardhero',
};

export function WebstoreService() {
    const api = PearlApiClient(PearlApps.VENDOR);

    const createWebstore = async (vendorId) => {
        const res = await api.post(`webstores`, { vendorId });

        if (!res.ok || !res.data) {
            return res;
        }

        return res.data;
    };

    // TODO: alpha mvp is one-to-one user/vendor/webstore
    const getWebstoreByUserId = async (userId, options = {}) => {
        const res = await api.get(`users/${userId}/webstores`);
        if (!res.ok || !res.data) {
            return null;
        }

        const webstores = res.data;
        const webstoreToLoad = webstores.find((w) => !!w.isVendorDefault) || webstores[0];

        if (webstoreToLoad.id) {
            const webstoreRes = await getWebstoreById(webstoreToLoad.id, options);
            if (webstoreRes.ok && webstoreRes.data) {
                return webstoreRes.data;
            }
            return null;
        }
        return null;
    };

    const getWebstoreById = (id, options = {}) => {
        return api.get(`webstores/${id}`, { removeInvalidImgSrc: options.removeInvalidImgSrc });
    };

    const deleteWebstoreById = async (id) => {
        const res = await api.delete(`webstores/${id}`);
        return unwrapResponse(res);
    };

    const getWebstoresByName = async (webstoreName, options = {}) => {
        if (webstoreName?.length > 3) {
            const res = await api.get(`webstores/name/${webstoreName}`, {
                removeInvalidImgSrc: options.removeInvalidImgSrc,
            });
            return res.data;
        } else {
            return [];
        }
    };

    const getWebstoreAvailability = async (webstoreId, start, end) => {
        const res = await api.get(
            `webstores/${webstoreId}/availability?startDate=${start}&endDate=${end}&flag=false`
        );

        if (res?.ok && res.data?.length > 0) {
            res.data.forEach((x) => {
                const [year, month, date] = x.availabilityDate.split('-');
                x.date = new Date(Number(year), Number(month) - 1, Number(date));
            });
            return res.data;
        }
        return [];
    };

    const deleteImage = async (webstoreId, imageId) => {
        const res = await api.delete(`webstores/${webstoreId}/images/${imageId}`);
        if (!res.ok || !res.data) {
            return res;
        }

        return res.data;
    };

    const getCheckWebstoreUrlAvailable = async (webstoreUrl, webstoreId) => {
        if (webstoreUrl?.length) {
            return await api.get(`webstores/available/${webstoreUrl}?wsid=${webstoreId}`);
        } else {
            // url is a '.required' field, which takes precedence over '.test'
            return Promise.resolve({
                ok: true,
                data: { urlIsAvailable: false },
            });
        }
    };

    const patchWebstore = async (data) => {
        return await api.patch('webstores', data);
    };

    const getSignedUrl = async (id, filename, category) => {
        const noCacheKey = uuidv4();

        const qs = [`filename=${filename}`, `category=${category}`, `noCacheKey=${noCacheKey}`];

        const res = await api.get(`webstores/${id}/uploadImage?${qs.join('&')}`);

        // TODO: error handling here
        return res?.data?.signedUrl;
    };

    const uploadImage = (id, file, category) => {
        return new Promise(async (resolve, reject) => {
            const client = apisauce.create({
                timeout: 60000,
            });

            const signedUrl = await getSignedUrl(id, file.name, category);

            const upload = await client.put(signedUrl, file, {
                headers: { 'Content-Type': 'image/*' },
            });

            if (upload.ok) {
                const res = await api.put(`webstores/${id}/confirmUploadImage`, {
                    category: category || IMAGE_CATEGORIES.GENERAL,
                    preSignedUrl: signedUrl,
                    contentType: file.type,
                    height: file.height,
                    width: file.width,
                    ordinal: 0,
                });
                if (res.ok) {
                    resolve(res.data.image);
                }
            } else {
                reject('Upload failed for', file.name);
            }
        });
    };

    const updateImage = async (webstoreId, imageId, data) => {
        return await api.put(`webstores/${webstoreId}/images/${imageId}`, {
            caption: data.caption,
            isCoverPhoto: data.isCoverPhoto,
        });
    };

    const patchWebstoreAvailability = async (id, data) => {
        return await api.patch(`webstores/${id}/availability`, data);
    };

    const putPublish = async (webstoreId) => {
        const res = await api.put(`webstores/${webstoreId}/publish`);
        return unwrapResponse(res);
    };

    const unpublish = async (draftId) => {
        const res = await api.put(`webstores/${draftId}/unpublish`);
        return unwrapResponse(res);
    };

    const putCopy = async (webstoreId) => {
        const res = await api.put(`webstores/${webstoreId}/copy`);
        return unwrapResponse(res);
    };

    const reorderImages = async (webstoreId, imageOrdinals) => {
        const res = await api.put(`webstores/${webstoreId}/reorderImages`, imageOrdinals);

        if (!res.ok || !res.data) {
            return null;
        }

        return res.data;
    };

    const getDbiStoreslist = async () => {
        const res = await api.get('webstores/stores-list');

        if (!res.ok || !res.data) {
            return null;
        }
        return res.data;
    };

    const favouriteStore = async ({ webstoreId, favouriteStore, zipcode, newRow }) => {
        const res = await api.post('webstores/favourite-store', {
            webstoreId,
            favouriteStore,
            zipcode,
            newRow,
        });

        if (!res.ok || !res.data) {
            return null;
        }
        return res.data;
    };

    return {
        createWebstore,
        getWebstoreByUserId,
        getWebstoreById,
        getWebstoresByName,
        getCheckWebstoreUrlAvailable,
        deleteWebstoreById,
        patchWebstore,
        uploadImage,
        deleteImage,
        updateImage,
        axiosInstance: api.axiosInstance,
        getWebstoreAvailability,
        patchWebstoreAvailability,
        putPublish,
        putCopy,
        reorderImages,
        unpublish,
        getDbiStoreslist,
        favouriteStore,
    };
}
