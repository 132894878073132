import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { EditSectionLayout } from '../EditSectionLayout/EditSectionLayout';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useOutletContext } from 'react-router-dom';
import { WebstoreSectionTypes } from 'framework';
import { useEffect, useRef, useState } from 'react';
import DbiStoreLocator from 'vendor/components/Webstore/SectionEdit/DbiStoreLocator/DbiStoreLocator';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { StorageService } from 'lib/StorageService';
import { SystemService } from 'lib/apis/SystemService';
import { useStore } from 'stores/Store';

const EditVendorDBStore = () => {
    const { webstore } = useWebstoreContext();
    const { onSubmit } = useOutletContext();
    const storageService = StorageService();
    const { appData } = useStore();
    const { categoryList } = appData;
    const [categoryListItems] = useState(
        categoryList.map((x) => {
            return {
                id: x.id,
                label: x.name,
                category: x,
            };
        })
    );
    const [favouriteStore, setFavouriteStore] = useState(null);
    const [zipcodeNearFavouriteStore, setZipcodeNearFavouriteStore] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [disableConfirmButton, setDisableConfirmButton] = useState(null);
    const [newZipcode, setNewZipcode] = useState(null);
    const [disableSave, setDisableSave] = useState(false);
    const webstoreApi = WebstoreService();
    const systemService = SystemService();
    const prevZipcode = useRef('');

    useEffect(async () => {
        const currentUser = await systemService.getWhoAmI();
        setCurrentUser(currentUser);
    }, []);

    const dbStoresList = JSON.parse(storageService.get('dbiStoresList'));
    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            YourZipCode: webstore.ZipcodeNearFavouriteStore,
        },
    };

    function isRecentUpdate(webstore) {
        const createdOn = new Date(webstore?.Created);

        const currentDate = new Date();

        const timeDifference = currentDate - createdOn;

        const daysDifference = timeDifference / (1000 * 3600 * 24);

        const difference = daysDifference <= 30 ? 'TRUE' : 'FALSE';
        return difference;
    }

    function getCategoryLabel(categoryListItems, VendorCategoryId) {
        const category = categoryListItems.find((item) => item.id === parseInt(VendorCategoryId));

        return category ? category.label : null;
    }
    useEffect(() => {
        if (favouriteStore || newZipcode) {
            setDisableConfirmButton(false);
        }
        if (disableSave) {
            setDisableConfirmButton(true);
        }
    }, [favouriteStore, newZipcode, disableSave, prevZipcode]);

    const handleSubmit = async (data) => {
        const contactName = currentUser.firstName + ' ' + currentUser.lastName;
        const newRow = JSON.stringify({
            'Store #': `Store ${favouriteStore}`,
            'Vendor Name': webstore.WebstoreName,
            'Contact Name': contactName,
            'Contact Phone': currentUser.phoneNumber,
            'Vendor Type': getCategoryLabel(categoryListItems, webstore.VendorCategoryId),
            Local: 'TRUE',
            National: 'FALSE',
            'Vendor Board': 'FALSE',
            'Vendor Bag': 'FALSE',
            'In-Store Visits': 'FALSE',
            'New in Past 30 Days': isRecentUpdate(webstore),
            'Item Type': 'Item',
            Path: 'sites/dbistores/Promotions/WeddingServices/Lists/Vendor List',
            'VendorId(For-Automation-Use)': webstore.VendorId,
            'WebstoreId(For-Automation-Use)': webstore.Id,
            'Created(For-Automation-Use)': webstore.Created,
        });
        if (favouriteStore) {
            await webstoreApi.favouriteStore({
                webstoreId: webstore.Id,
                favouriteStore,
                zipcode: zipcodeNearFavouriteStore,
                newRow,
            });
            onSubmit(data);
            setDisableConfirmButton(true);
        }
    };

    return (
        <PearlForm
            formName="EditAbout"
            formOptions={formOptions}
            onSubmit={handleSubmit}
            formType={FormType.EDITABLE}
        >
            <EditSectionLayout
                sectionName={WebstoreSectionTypes.YOUR_DAVID_BRIDAL_STORE}
                hideConfirmButton={disableConfirmButton}
                showConfirmButton={true}
                hideSaveButton={true}
            >
                {webstore.SubscriptionTier === 'premium' && dbStoresList && (
                    <DbiStoreLocator
                        setFavouriteStore={setFavouriteStore}
                        setZipcodeNearFavouriteStore={setZipcodeNearFavouriteStore}
                        dbiStoresList={dbStoresList}
                        setNewZipcode={setNewZipcode}
                        setDisableSave={setDisableSave}
                    />
                )}
            </EditSectionLayout>
        </PearlForm>
    );
};

export { EditVendorDBStore };
