import { PearlIcon, IconSizes, IconName } from 'dbi-pearl-ui-kit';
import { PearlAlertBannerStyled } from './PearlAlertBanner.styled';

const PearlAlertBanner = (props) => {
    const { handleOnClose, bodyText, subTextBullets, icon, type } = props;
    return (
        <PearlAlertBannerStyled type={type}>
            <span className="alert-header-row">
                <PearlIcon iconName={icon} size={IconSizes.SM} />
                <span className="h5 alert-body">
                    <b>{bodyText}</b>
                </span>
                {handleOnClose && (
                    <button
                        className="close-alert"
                        type="button"
                        onClick={() => handleOnClose()}
                        aria-label="close alert"
                    >
                        <PearlIcon iconName={IconName.CLOSE} size={IconSizes.SM} />
                    </button>
                )}
            </span>
            {subTextBullets?.length > 0 && (
                <div className="p3">
                    <ul>
                        {subTextBullets?.map((msg) => (
                            <li key={msg}>{msg}</li>
                        ))}
                    </ul>
                </div>
            )}
        </PearlAlertBannerStyled>
    );
};

export { PearlAlertBanner };
