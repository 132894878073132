import React, { createContext, useContext, useState, useEffect } from 'react';
import { WebstoreService } from '../../shared/lib/apis/WebstoreService';
import { createSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../stores/Store';
import {
    buildDefaultSearchContext,
    emptyFilters,
    deriveLocation,
} from './util/GenericSearchFunctions';

const PearlSearchContext = createContext();
PearlSearchContext.displayName = 'PearlSearchContext';

// PearlSearchContext is Pearl's primary search that controls the home page and Local Vendor Listing page
// 'loading' is used to control search page components to trigger new searches when loading is true
// This allows search pages to reflect the selected state
const usePearlSearch = () => {
    const [searchContext, setSearchContext] = useContext(PearlSearchContext);

    const {
        loading,
        hasMoreResults,
        currentPage,
        pageSize,
        sortBy,
        numResults,
        searchResults,
        category,
        location,
        filters,
        loadingMore,
    } = searchContext;

    const { appData } = useStore();

    const webstoreApi = WebstoreService();

    const buildParams = (filters) => {
        const queryParams = {};

        if (filters.subCategories?.length) {
            queryParams.subCategoryIds = filters.subCategories.map((s) => s.id).join();
        }
        if (filters.styles?.length) {
            queryParams.styles = filters.styles.map((s) => s.id).join();
        }
        if (filters.priceTiers?.length) {
            queryParams.priceTiers = filters.priceTiers.map((p) => p.id).join();
        }
        if (filters.availabilityDate) {
            queryParams.availabilityDate = filters.availabilityDate;
        }
        if (filters.capacity?.length) {
            queryParams.capacity = filters.capacity.map((c) => c.id).join();
        }
        if (filters.serviceAmenities?.length) {
            queryParams.services = filters.serviceAmenities.map((s) => s.id).join();
        }
        if (filters.name) {
            queryParams.name = filters.name;
        }
        if (filters.shippingOptions?.length) {
            queryParams.shippingOptions = filters.shippingOptions.join();
        }
        if (filters.travelOptions?.length) {
            queryParams.travelOptions = filters.travelOptions.join();
        }
        if (filters.resultsIncludeFeatures) {
            queryParams.resultsIncludeFeatures = filters.resultsIncludeFeatures;
        }
        if (filters.resultsExcludeFeatures) {
            queryParams.resultsExcludeFeatures = filters.resultsExcludeFeatures;
        }
        if (filters.serviceAreas?.length) {
            queryParams.serviceAreas = filters.serviceAreas.join();
        }
        if (filters.pageSize) {
            queryParams.pageSize = filters.pageSize;
        }
        if (filters.page) {
            queryParams.page = filters.page;
        }
        if (sortBy) {
            queryParams.sort = sortBy;
        }
        return queryParams;
    };

    // eslint-disable-next-line complexity
    const buildSearchParams = (f) => {
        if (!f || !category) {
            return null;
        }
        let d = null;
        if (f.availabilityDate) {
            d = f.availabilityDate ? new Date(f.availabilityDate) : null;
        }

        const params = {
            subcategories: (f.subCategories?.map((sa) => sa.externalId) || []).join('-'),
            styles: (f.styles?.map((sa) => sa.externalId) || []).join('-'),
            price: (f.priceTiers?.map((sa) => sa.externalId) || []).join('-'),
            date: d && d.getFullYear() + '-' + d.getMonth() + '-' + d.getDay(),
            capacity: (f.capacity?.map((sa) => sa.externalId) || []).join('-'),
            services: (f.serviceAmenities?.map((sa) => sa.externalId) || []).join('-'),
            serviceAreas: (f.serviceAreas || []).join('-'),
            shippingOptions: (f.shippingOptions || []).join('-'),
            travelOptions: (f.travelOptions || []).join('-'),
            resultsIncludeFeatures: f.resultsIncludeFeatures?.split(',')?.join('-') || [],
            resultsExcludeFeatures: f.resultsExcludeFeatures?.split(',')?.join('-') || [],
        };

        Object.keys(params).forEach((key) => {
            if (!params[key]) {
                delete params[key];
            }
        });

        return createSearchParams(params);
    };

    const sanitizeSearchUrlParams = (searchParams = {}, paramNamesToStrip = []) => {
        const sanitizedParamsArray = Object.entries(searchParams).filter(
            ([k, v]) => !paramNamesToStrip.includes(k)
        );
        const sanitizedParamsObject = Object.fromEntries(sanitizedParamsArray);

        return sanitizedParamsObject;
    };

    const getSearchPath = (cat, loc, filters) => {
        const locText = loc?.displayText;

        if (cat?.externalId && locText) {
            const sanitizedLoc = locText.replaceAll(',', '-').replaceAll(' ', '').toLowerCase();
            const sanitizedCategoryLabel = cat.externalId;
            const searchParams = buildSearchParams(filters);
            if (searchParams?.toString()) {
                return `/vendors/${sanitizedLoc}/${sanitizedCategoryLabel}?${searchParams}`;
            } else {
                return `/vendors/${sanitizedLoc}/${sanitizedCategoryLabel}`;
            }
        }
        return null;
    };

    const setSortBy = (sortByVal) => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            sortBy: sortByVal.id,
            loading: true,
        }));
    };

    const clearSortBy = () => {
        setSortBy(null);
    };

    const clearSearch = () => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            category: null,
            location: null,
            searchResults: null,
            filters: emptyFilters,
            numResults: null,
            resultsIncludeFeatures: null,
            resultsExcludeFeatures: null,
        }));
    };

    const setFilters = (filterKey, val, destructureValObject = false) => {
        let newFilters = {};
        if (destructureValObject) {
            newFilters = {
                ...filters,
                ...val,
            };
        } else {
            newFilters = {
                ...filters,
                [filterKey]: val,
            };
        }

        setSearchContext((searchContext) => ({
            ...searchContext,
            filters: newFilters,
            loading: true,
        }));
    };

    const clearFilters = () => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            filters: emptyFilters,
            loading: true,
        }));
    };

    const clearFiltersAndResetSearch = () => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            filters: emptyFilters,
            currentPage: 1,
            numResults: null,
            searchResults: null,
            loading: true,
        }));
    };

    const setLoading = (loadingVal) => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            loading: loadingVal,
        }));
    };

    const setLoadingMore = (loadingVal) => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            loadingMore: loadingVal,
        }));
    };

    const setSearchCategory = (cat, options = {}) => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            category: cat,
            filters: emptyFilters,
        }));
    };

    const setSearchLocation = (loc, options = {}) => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            location: loc,
        }));
    };

    const setPageSize = (size) => {
        setSearchContext((searchContext) => ({
            ...searchContext,
            pageSize: size,
        }));
    };

    const runSearch = async (loc, cat, filters, updateParams = true, options = {}) => {
        // const queryParams = buildParams(filters);
        loc = loc || appData.currentLocation;

        let res;

        if (cat && loc) {
            // res = await webstoreApi.searchWebstores({
            //     categoryId: cat.id,
            //     latitude: loc.latitude,
            //     longitude: loc.longitude,
            //     pageSize: options.pageSize || pageSize,
            //     // Only count the category for a search made by a user once (do not count 'load more' clicks)
            //     // Our code generally runs multiple searches when a user makes a search (to differentiate between premium & freemium webstores)
            //     // We only want to count a user search category once, even if the code is making multiple calls
            //     // e.g.: Only update the category count for the premiere search and not the freemium search when they are both on the same page
            //     updateSearchKpiCount: options.updateSearchKpiCount
            //         ? options.updateSearchKpiCount
            //         : false,
            //     ...queryParams,
            // });
            // if (options.setTotalListingCount) {
            //     options.setTotalListingCount((totalListingCount) => ({
            //         ...totalListingCount,
            //         premiere: res.totalResults || 0,
            //     }));
            // }
        }

        if (!loadingMore) {
            let newResults = [];
            if (res?.results) {
                newResults = [...res.results];
            }

            if (searchResults?.length > 0) {
                newResults.push(searchResults);
            }

            if (updateParams) {
                setSearchContext((searchContext) => ({
                    ...searchContext,
                    location: loc,
                    category: cat,
                    searchResults: res?.results || [],
                    numResults: res?.totalResults || 0,
                    currentPage: res?.meta.page,
                    filters: filters,
                    loading: false,
                    hasMoreResults: newResults.length < res?.totalResults,
                    init: false,
                }));
            } else {
                setSearchContext((searchContext) => ({
                    ...searchContext,
                    searchResults: res?.results || [],
                    numResults: res?.totalResults || 0,
                    currentPage: res?.meta.page,
                    loading: false,
                    hasMoreResults: newResults.length < res?.totalResults,
                    init: false,
                }));
            }
            searchContext.loading = false;
        }
    };

    const loadMore = async (options = {}) => {
        setLoading(true);
        setLoadingMore(true);

        let params = options.searchParams;

        if (!options.searchParams) {
            params = buildParams(filters);
        }

        const res = await webstoreApi.searchWebstores({
            categoryId: category.id,
            latitude: location?.latitude,
            longitude: location?.longitude,
            page: currentPage + 1,
            pageSize: options.pageSize || pageSize,
            // The 1st call should already have counted the category, do not count the category for load more clicks
            updateSearchKpiCount: false,
            ...params,
        });
        const newResults = [...searchResults, ...res?.results];
        setSearchContext((searchContext) => ({
            ...searchContext,
            searchResults: newResults,
            numResults: res.totalResults,
            currentPage: res.meta.page,
            loading: false,
            hasMoreResults: newResults.length < res?.totalResults,
        }));

        setLoadingMore(false);
    };

    searchContext.runSearch = runSearch;
    searchContext.loadMore = loadMore;

    return {
        ...searchContext,
        searchResults,
        numResults,
        currentPage,
        buildParams,
        setPageSize,
        pageSize,
        clearSearch,
        setSortBy,
        clearSortBy,
        setFilters,
        clearFilters,
        clearFiltersAndResetSearch,
        sortBy,
        setSearchCategory,
        category,
        setSearchLocation,
        location,
        deriveLocation,
        loading,
        loadingMore,
        hasMoreResults,
        filters,
        buildSearchParams,
        getSearchPath,
        setLoading,
        setLoadingMore,
        sanitizeSearchUrlParams,
    };
};

const PearlSearchProvider = ({ children }) => {
    const [searchContext, setSearchContext] = useState({});
    const [isInitialized, setIsInitialized] = useState(false);

    const { pathname } = useLocation();
    const { appData } = useStore();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        // Only build default context if context is empty to prevent overwriting existing context on context change
        if (!Object.keys(searchContext).length > 0) {
            const defaultSearchCategory = appData.getDefaultSearchCategory();
            const defaults = buildDefaultSearchContext(
                defaultSearchCategory,
                appData.currentLocation,
                null
            );
            setSearchContext(defaults);
        }
        setIsInitialized(true);
    }, []);

    return (
        <PearlSearchContext.Provider value={[searchContext, setSearchContext]}>
            {!!isInitialized && children}
        </PearlSearchContext.Provider>
    );
};

export { PearlSearchProvider, usePearlSearch };
