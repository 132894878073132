import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu';
import { GlobalNavigationStyled } from './GlobalNavigation.styled';
import { PearlBlack } from 'assets/logos/LogoImages';
import { APP_ROUTES, navigation } from '../../../constants/navigation';
import { UserNavigationMenu } from '../UserNavigationMenu/UserNavigationMenu';
import { PearlApps, NewTabLink } from 'framework';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { getAppName } from 'shared/hooks/AppName';
import useWindowSize from 'vendor/hooks/useWindowSize';

const InternalLink = (props) => {
    const { link, translator } = props;

    return (
        <Link to={link.src} state={link.stateToSend}>
            <label>
                <b>{translator(link.displayName)}</b>
            </label>
        </Link>
    );
};

const ExternalLink = (props) => {
    const { link, translator } = props;
    return (
        <NewTabLink
            link={link.src}
            target={
                link.displayName === 'Navigation.GlobalNavigationBar.ReturnToDavids'
                    ? '_self'
                    : '_blank'
            }
        >
            <label>
                <b>{translator(link.displayName)}</b>
            </label>
        </NewTabLink>
    );
};

const GlobalNavigation = (props) => {
    const { children } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();
    const { width } = useWindowSize();

    const appName = getAppName();

    let bottomBarLinks;

    if (authUser.isAuthedVendor && appName === PearlApps.VENDOR) {
        bottomBarLinks = navigation.vendorUserGlobalBottomBar;
    } else if (authUser.isAuthedEventUser && appName === PearlApps.EVENT_USER) {
        bottomBarLinks = navigation.eventUserGlobalBottomBar;
    } else {
        bottomBarLinks = navigation.unauthedGlobalBottomBar;
    }

    return (
        <GlobalNavigationStyled data-testid="global-navigation" className={'fixedBottomBar'}>
            <div className="bottomBar">
                <div className="leftBottomBar">
                    {width < 815 && (
                        <div className="hamburger" data-testid="hamburger-menu">
                            <HamburgerMenu />
                        </div>
                    )}
                    <button
                        id="pearl-landing-logo-desktop-link"
                        onClick={() => navigate(APP_ROUTES.landing)}
                        className="logoContainer"
                        aria-label={`${t('Navigation.AriaLabel')} ${t(
                            'Navigation.GlobalNavigationBar.Pearl'
                        )}`}
                    >
                        <img
                            className="headerLogo"
                            src={PearlBlack.default}
                            alt={t('Navigation.GlobalNavigationBar.PearlLogoAlt')}
                        />
                    </button>
                    {width > 815 && (
                        <div className="linksContainer">
                            {bottomBarLinks.map((link) => {
                                if (link.external) {
                                    return (
                                        <ExternalLink
                                            link={link}
                                            translator={t}
                                            key={link.displayName}
                                        />
                                    );
                                } else {
                                    return (
                                        <InternalLink
                                            link={link}
                                            translator={t}
                                            key={link.displayName}
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
                {width > 815 && (
                    <div className="userControls" data-testid="user-navigation-menu">
                        <UserNavigationMenu />
                    </div>
                )}
            </div>
            {children}
        </GlobalNavigationStyled>
    );
};

export { GlobalNavigation };
