import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { useTranslation } from 'react-i18next';
import { NoInformationProvided } from 'vendor/components/Webstore/NoInformationProvided/NoInformationProvided';
import VendorDBStoreStyled from './VendorDBStore.style';
import { StorageService } from 'lib/StorageService';
import { getDistance } from 'vendor/components/Webstore/SectionEdit/DbiStoreLocator/DbiStoreLocator';
import { GeocodeService } from 'lib/apis/GeocodeService';
import { ReactComponent as Calendar } from '../../../../assets/SVG-Images/Calendar-checkmark.svg';
import { IconSizes, PearlIcon, SolidButton } from 'dbi-pearl-ui-kit';
import { useUpgradeSubscription } from 'vendor/hooks/UpgradeSubscription';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';

const VendorDBStore = (props) => {
    const { webstore, sectionRef, sectionId } = props;
    const { t } = useTranslation();
    const { vendor } = useVendorContext();
    const geocodeService = GeocodeService();
    const storageService = StorageService();
    const webstoreApi = WebstoreService();
    const [dbStoreList, setDbStoreList] = useState(null);
    const [favStore, setFavStore] = useState(null);
    const [mileDistance, setMileDistance] = useState(null);

    useEffect(() => {
        async function fetchStores() {
            let stores;
            const cachedStores = storageService.get('dbiStoresList');

            if (cachedStores) {
                stores = JSON.parse(cachedStores);
            } else {
                stores = await webstoreApi.getDbiStoreslist();
                storageService.set('dbiStoresList', JSON.stringify(stores));
            }

            setDbStoreList(stores);
        }

        fetchStores();
    }, []);

    useEffect(() => {
        if (!dbStoreList || !webstore.FavouriteStore) return;

        const favouriteStore = dbStoreList.find(
            (store) => store.store_number === webstore.FavouriteStore
        );
        setFavStore(favouriteStore);

        if (webstore.ZipcodeNearFavouriteStore && favouriteStore) {
            (async () => {
                const currentLoc = await geocodeService.getGeocodeByZipcode(
                    webstore.ZipcodeNearFavouriteStore
                );
                const distance = getDistance({
                    currentLocation: {
                        lat: currentLoc.latitude,
                        lng: currentLoc.longitude,
                    },
                    targetLocation: { lat: favouriteStore?.lat, lng: favouriteStore?.lng },
                });
                setMileDistance(distance);
            })();
        }
    }, [dbStoreList, webstore.FavouriteStore]);

    const { upgrade } = useUpgradeSubscription();

    const onUpgradeClick = () => {
        upgrade(vendor);
    };

    const handleClick = () => {
        const redChatButton = [...document.querySelectorAll('.LPMcontainer')].find(
            (el) => el.style.backgroundColor === 'rgb(232, 24, 76)'
        );
        if (redChatButton) redChatButton.click();
    };

    const postal_code = favStore?.postal_code.split('-');

    return (
        <VendorDBStoreStyled ref={sectionRef} id={sectionId}>
            <SectionHeader
                title={
                    webstore.SubscriptionTier === 'premium'
                        ? t('WebstoreSections.DBStore.Title')
                        : 'Preferred David’s Store'
                }
                description={t('WebstoreSections.DBStore.Description')}
            />

            {webstore?.FavouriteStore && favStore && webstore.SubscriptionTier === 'premium' ? (
                <>
                    <div className="store-description-container">
                        <Calendar className="calendar-style" />
                        <p className="description-style">
                            {t('WebstoreSections.DBStore.DropoffText')}
                        </p>
                    </div>
                    <p className="current-store-style">{t('Forms.EditAbout.YourCurrentStore')}</p>
                    <div className="store-container">
                        <p className="store-name-style">{favStore.store_name}</p>
                        <p className="store-address-style">{favStore.address}</p>
                        <p className="store-address-style">
                            {favStore.city}, {favStore.state_or_province}, {postal_code[0]}
                        </p>
                        {mileDistance && (
                            <p className="store-distance-style">
                                {mileDistance.toFixed(1)} {t('Forms.EditAbout.MilesAway')}
                            </p>
                        )}
                    </div>
                    <p className="change-location-style">
                        {t('Forms.EditAbout.ContactSupportText')}
                        <span
                            onClick={handleClick}
                            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && handleClick()}
                            role="button"
                            tabIndex={0}
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {t('Forms.EditAbout.ContactSupport')}
                        </span>
                    </p>
                </>
            ) : (
                webstore.SubscriptionTier === 'premium' && (
                    <NoInformationProvided sectionRef={sectionRef} sectionId={sectionId} />
                )
            )}
            {webstore.SubscriptionTier !== 'premium' && (
                <>
                    <div className="store-description-container">
                        <PearlIcon iconName="dbcc" size={IconSizes.XXL} className="db-icon-style" />
                        <p className="description-style">
                            {t('WebstoreSections.DBStore.NonPremiumUsersDescription')}
                        </p>
                    </div>
                    <div className="premium-users-style">
                        <p className="current-store-style">
                            {t('WebstoreSections.DBStore.PremiumUsersOnly')}
                        </p>
                        <SolidButton onClick={onUpgradeClick}>
                            {t('WebstoreSections.DBStore.SubscribeToPremium')}
                        </SolidButton>
                    </div>
                </>
            )}
        </VendorDBStoreStyled>
    );
};
export default VendorDBStore;
