import { APP_ROUTES } from '../constants/navigation';
import { useNavigate } from 'react-router-dom';
import { WebstoreService as SharedWebstoreService } from '../lib/apis/WebstoreService';
import { WebstoreService as VendorWebstoreService } from '../../vendor/lib/apis/WebstoreService';
import { VendorService } from '../../vendor/lib/apis/VendorService';
import { usePearlToast } from 'framework';
import { unwrapResponse } from '../../lib/apis/utils';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/Store';
import { StorageService, StorageKeys } from '../../lib/StorageService';

const useClaimYourBusiness = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const sharedWebstoreService = SharedWebstoreService();
    const vendorWebstoreService = VendorWebstoreService();
    const vendorService = VendorService();
    const storageService = StorageService();

    // useWebstoreContext breaks here for some reason
    // error mentions translations
    // useStore instead, but maybe fix later?
    const { authUser, vendor, webstore } = useStore();

    const { toastError } = usePearlToast();

    const getClaimableStore = () => {
        const stringified = storageService.get(StorageKeys.cybInProgressWebstore);
        return JSON.parse(stringified);
    };

    const setClaimableStore = (webstoreToClaim) => {
        storageService.set(StorageKeys.cybInProgressWebstore, JSON.stringify(webstoreToClaim));
    };

    const clearClaimableStore = () => {
        storageService.remove(StorageKeys.cybInProgressWebstore);
    };

    const showClaimPage = (webstoreToClaim) => {
        setClaimableStore(webstoreToClaim);
        if (webstoreToClaim.IsCYB) {
            navigate(APP_ROUTES.claimBusiness.helpfulInformation);
        } else {
            navigate(APP_ROUTES.claimBusiness.claimForm, {
                state: {
                    typeOfForm: 'Already',
                    webstoreToClaim: webstoreToClaim,
                },
            });
        }
    };

    const showSendEmailPage = (webstoreToClaim) => {
        setClaimableStore(webstoreToClaim);
        if (webstoreToClaim.IsCYB) {
            navigate(APP_ROUTES.claimBusiness.sendEmail);
        } else {
            navigate(APP_ROUTES.claimBusiness.claimForm, {
                state: {
                    typeOfForm: 'Already',
                    webstoreToClaim: webstoreToClaim,
                },
            });
        }
    };

    const beginClaim = async (webstoreToClaim) => {
        setClaimableStore(webstoreToClaim);
        try {
            await sharedWebstoreService.beginClaimWebstore(webstoreToClaim.Id);
            navigate(APP_ROUTES.claimBusiness.verifyEmail);
        } catch (e) {
            toastError();
            throw e;
        }
    };

    const resendCode = async (webstoreToClaim) => {
        await sharedWebstoreService.beginClaimWebstore(webstoreToClaim.Id);
    };

    const completeClaim = async (webstoreToClaim, code) => {
        try {
            // claim the store
            await sharedWebstoreService.claimWebstore(webstoreToClaim.Id, code);

            // get the updated (claimed) draft store
            const webstoreRes = await vendorWebstoreService.getWebstoreById(
                webstoreToClaim.DraftWebstoreId,
                { removeInvalidImgSrc: false }
            );
            const updatedWebstore = unwrapResponse(webstoreRes);

            // update context with the new webstore
            webstore.clear();
            webstore.setFromApi(updatedWebstore);

            // user may not have been a vendor before, 'claim' makes them one
            // need to do this AFTER setting webstore to prevent
            // reactions from overwriting newly set webstore when/if vendor changes
            const updatedVendor = await vendorService.getVendorByUsername(authUser.Username);

            if (updatedVendor) {
                vendor.updateVendor(updatedVendor);
                // and now we need to update the user record to
                // reflect their new vendor role
                authUser.Roles.Vendor = true;
            } else {
                // TODO: else? (what happens if no vendor record)
            }

            clearClaimableStore();
            // TODO: Tos needed?
            navigate(APP_ROUTES.vendor.webstore.setup);
        } catch (e) {
            toastError(t('Pages.ClaimVerifyYourId.CodeError'));
            throw e;
        }
    };

    return {
        getClaimableStore,
        setClaimableStore,
        showClaimPage,
        showSendEmailPage,
        beginClaim,
        resendCode,
        completeClaim,
        clearClaimableStore,
    };
};

export { useClaimYourBusiness };
